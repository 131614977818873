export default [
  { path: '/', name: 'Home', view: 'Home', text: 'Dashboard', loginRequired: false },
  { path: '/login', name: 'Login', view: 'Home', text: 'Login', loginRequired: true },
  { path: '/settings', name: 'Settings', view: 'Settings', loginRequired: true },

  // { path: '/archives', name: 'Archives', view: 'Archives/Home', loginRequired: false },
  // { path: '/archives/admin', name: 'ArchiveAdmin', view: 'Archives/Admin', loginRequired: true },
  // { path: '/archives/:id', name: 'ArchiveItem', view: 'Archives/Item', loginRequired: false },

  // { path: '/academics/new-course', name: 'Academics/NewCourse', view: 'Academics/NewCourse/Form', title: 'New Course Submission', loginRequired: true, roles: [ 'Technology Services', 'Employee', 'Faculty' ] },
  // { path: '/academics/new-course/admin', name: 'Academics/NewCourse/Admin', view: 'Academics/NewCourse/Admin', title: 'New Course Admin', loginRequired: true, roles: [ 'Technology Services', 'Employee', 'Faculty' ] },

  // { path: '/accomodations', name: 'AccomodationsRequest', view: 'Accommodations/Redirect', title: 'Accomodations Status', loginRequired: false, includeInMenu: false },
  // { path: '/accommodations', name: 'AccommodationsRequest', view: 'Accommodations/Home', title: 'Accommodations Status', loginRequired: false, includeInMenu: false },
  // { path: '/accommodations/request', name: 'AccommodationsRequestForm', view: 'Accommodations/Request', title: 'Accommodations Request', loginRequired: false, includeInMenu: false },
  // { path: '/accommodations/internal', name: 'AccommodationsRequestInternal', view: 'Accommodations/Internal', title: 'Accommodations Reservation', loginRequired: false, includeInMenu: false },
  // { path: '/accommodations/manage', name: 'AccommodationsManageRequest', view: 'Accommodations/Manage', title: 'Manage Accommodations Request', loginRequired: false, includeInMenu: false },
  // { path: '/accommodations/admin', name: 'AccommodationsRequestAdmin', view: 'Accommodations/Admin', title: 'Accommodations Request Admin', loginRequired: true, roles: ['Technology Services'], includeInMenu: false },

  { path: '/alumni/update', name: 'Alumni/Update/NoId', view: 'Alumni/UpdateNoId', loginRequired: false },
  { path: '/alumni/update/:id', name: 'Alumni/Update/Id', view: 'Alumni/Update', loginRequired: false },

  { path: '/banner', name: 'Banner', view: 'Test', loginRequired: false },

  { path: '/board', name: 'Board/Home', view: 'Board/Home', title: 'Files', loginRequired: false, includeInMenu: true },
  { path: '/board/admin', name: 'Board/Admin', view: 'Board/Admin', title: 'Admin', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Presidents Office Staff', 'Development' ] },
  { path: '/board/login', name: 'Board/Login', view: 'Board/Login', title: 'Files', loginRequired: true, includeInMenu: false },
  { path: '/board/reset', name: 'Board/Reset', view: 'Board/Reset', title: 'Reset', loginRequired: false, includeInMenu: false },

  { path: '/calendar/classes', name: 'Calendar/ClassSchedule', view: 'Calendar/Classes', title: 'Class Calendar', includeInMenu: true, loginRequired: true, roles: [ 'All Employees', 'Employee' ] },
  { path: '/calendar/schedule/', name: 'Calendar/MySchedule', view: 'Calendar/Schedule', title: 'Student Class Schedule', includeInMenu: true, loginRequired: true },
  { path: '/calendar/schedule/:id?', name: 'Calendar/Schedule', view: 'Calendar/Schedule', title: 'Student Class Schedule', includeInMenu: false, loginRequired: true, roles: [ 'All Employees', 'Employee' ] },
  { path: '/calendar/term-setup', name: 'Calendar/TermSetup', view: 'Calendar/TermSetup', title: 'Term Setup', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Records Office', 'Records', 'Student Development' ] },

  { path: '/chapel', name: 'Chapel/Schedule', view: 'Chapel/Schedule', title: 'Chapel Schedule', includeInMenu: true, loginRequired: false },
  { path: '/chapel/checker', name: 'Chapel/Checker', view: 'Chapel/Checker', title: 'Chapel Checker', includeInMenu: true, loginRequired: true },
  { path: '/chapel/creditor', name: 'Chapel/Creditor', view: 'Chapel/Creditor', title: 'Chapel Creditor', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Chapel Office', 'Chapel', 'Chapel Workstudy' ] },
  { path: '/chapel/review', name: 'Chapel/Review', view: 'Chapel/Review', title: 'Chapel Summary Review', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Chapel Office', 'Chapel', 'Chapel Reviewer' ] },
  { path: '/chapel/students', name: 'Chapel/Students', view: 'Chapel/Students', title: 'Chapel Students', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Chapel Office', 'Chapel' ] },
  { path: '/chapel/students/:id/:type?', name: 'Chapel/Students/InChapel', view: 'Chapel/Students', title: 'Chapel Students', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Chapel Office', 'Chapel' ] },
  { path: '/chapel/student/:id/:term', name: 'Chapel/Student/Detail', view: 'Chapel/Detail', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Chapel Office', 'Chapel' ] },

  { path: '/checkin', name: 'Checkin/Scanner', view: 'Checkin/Scanner' },
  { path: '/checkin/event', name: 'Checkin/Event/List', view: 'Checkin/EventList', title: 'Check-In Events', includeInMenu: true, loginRequired: true, roles: ['All Employees', 'Employee'] },
  { path: '/checkin/event/:id', name: 'Checkin/Event', view: 'Checkin/Event', title: 'Check-In Event', includeInMenu: false, loginRequired: true, roles: ['All Employees', 'Employee'] },
  { path: '/checkin/department', name: 'Checkin/Department', view: 'Checkin/Department', title: 'Check-In Department Options', includeInMenu: true, loginRequired: true, roles: ['All Employees', 'Employee'] },

  { path: '/cov-cares', name: 'CovCares/Home', view: 'CovCares/Home', title: 'Cov-Cares Home', includeInMenu: true, loginRequired: true, roles: ['All Faculty', 'Faculty', 'Employee', 'Technology Services'] },
  { path: '/cov-cares/alert', name: 'CovCares/Alert', view: 'CovCares/Alert', title: 'Cov-Cares Alert', includeInMenu: false, loginRequired: true, roles: ['All Faculty', 'Faculty', 'Employee', 'Technology Services'] },
  { path: '/cov-cares/stoplight', name: 'CovCares/Stoplight/Survey', view: 'CovCares/Stoplight/Surveys', title: 'Stoplight Survey', includeInMenu: false, loginRequired: true },
  { path: '/cov-cares/stoplight/:id', name: 'CovCares/Stoplight/Survey/Students', view: 'CovCares/Stoplight/Students', title: 'Stoplight Survey Students', includeInMenu: false, loginRequired: true },
  { path: '/cov-cares/tasks', name: 'CovCares/Tasks', view: 'CovCares/Task', title: 'Cov-Cares Tasks', includeInMenu: true, loginRequired: true, roles: ['All Faculty', 'Faculty', 'Employee', 'Technology Services'] },

  // { path: '/discussions', name: 'Discussions', view: 'Test', loginRequired: true },
  // { path: '/discussions/posts', name: 'DiscussionPosts', view: 'Test', loginRequired: true },
  // { path: '/discussions/pinned', name: 'DiscussionsPinned', view: 'Test', loginRequired: true },

  // { path: '/event', name: 'Events', view: 'Test', loginRequired: false },
  // { path: '/event/calendar', name: 'EventCalendar', view: 'Test', loginRequired: true },
  // { path: '/event/list', name: 'EventList', view: 'Test', loginRequired: false },
  // { path: '/event/admin', name: 'EventAdmin', view: 'Test', loginRequired: true },

  { path: '/forms', name: 'Form/List', view: 'Forms/List', title: 'Forms: List', includeInMenu: true, loginRequired: false },
  { path: '/forms/admin', name: 'Form/Admin/List', view: 'Forms/Admin/List', title: 'Forms: Admin', includeInMenu: true, loginRequired: true },
  { path: '/forms/admin/options', name: 'Form/Admin/Option/List', view: 'Forms/Admin/Options', title: 'Forms: Option Editor', includeInMenu: true, loginRequired: true, roles: ['All Employees', 'Employee'] },
  { path: '/forms/admin/options/:id', name: 'Form/Admin/Option/Editor', view: 'Forms/Admin/OptionEditor', title: 'Forms: Option Editor', loginRequired: true },
  { path: '/forms/admin/submission/:id', name: 'Form/Admin/Submission/Viewer', view: 'Forms/Admin/Submission', title: 'Forms: Submission', loginRequired: true },
  { path: '/forms/admin/:id', name: 'Form/Admin/Editor', view: 'Forms/Admin/Editor', title: 'Forms: Editor', loginRequired: true },
  { path: '/forms/:form/:revision?', name: 'Form/Viewer', view: 'Forms/Form', title: 'Form Viewer', loginRequired: false },

  { path: '/greatscots', name: 'GreatScots', view: 'GreatScots/Home', title: 'GreatScots Home', includeInMenu: true, loginRequired: true },
  { path: '/greatscots/lists', name: 'GreatScotsLists', view: 'GreatScots/Lists', title: 'GreatScots Lists', includeInMenu: true, loginRequired: true },
  { path: '/greatscots/class/:id', name: 'GreatScotsClass', view: 'GreatScots/Class', title: 'GreatScots Class List', loginRequired: true },
  { path: '/greatscots/list/:id', name: 'GreatScotsList', view: 'GreatScots/List', loginRequired: true },
  { path: '/greatscots/editor', name: 'GreatScotsEditor', view: 'GreatScots/Editor', title: 'GreatScots Editor', includeInMenu: false, loginRequired: true, roles: ['All Employees', 'Employee'] },
  { path: '/greatscots/edit', name: 'GreatScotsEditHome', view: 'GreatScots/Edit/Home', title: 'GreatScots Editor', includeInMenu: true, loginRequired: true, roles: ['All Employees', 'Employee'] },
  { path: '/greatscots/edit/:id', name: 'GreatScotsEditList', view: 'GreatScots/Edit/List', title: 'GreatScots Edit List', includeInMenu: false, loginRequired: true, roles: ['All Employees', 'Employee'] },
  { path: '/greatscots/uploader', name: 'GreatScotsUploader', view: 'GreatScots/Uploader', title: 'GreatScots Uploader', includeInMenu: false, loginRequired: true },

  { path: '/library/archives', name: 'Library/Archive', view: 'Library/Archives/Categories', title: 'Library Archives', includeInMenu: true, loginRequired: false },
  { path: '/library/archives/:entry([a-f\\d]{24})', name: 'Librar/yArchive/Entry', view: 'Library/Archives/Entry', title: 'Library Archive Entry', includeInMenu: false, loginRequired: false },
  { path: '/library/archives/:category', name: 'Library/Archive/Category', view: 'Library/Archives/Entries', title: 'Library Archive Entries', includeInMenu: false, loginRequired: false },
  { path: '/library/capstone-carrel', name: 'Library/Carrel', view: 'Library/Carrel/Home', title: 'Library Carrel Sign-Up', includeInMenu: true, loginRequired: true },
  { path: '/library/capstone-carrel/admin', name: 'Library/Carrel/Admin', view: 'Library/Carrel/Admin', title: 'Library Carrel Admin', includeInMenu: true, loginRequired: true, roles: ['Technology Services', 'Library'] },

  { path: '/mailroom', name: 'Mailroom/Client', view: 'Mailroom/Client', title: 'Mailroom Home', includeInMenu: true, loginRequired: true },
  { path: '/mailroom/admin', name: 'Mailroom/Admin', view: 'Mailroom/Admin/Home', title: 'Mailroom', includeInMenu: false, loginRequired: true, roles: ['Technology Services', 'Office Services'] },
  { path: '/mailroom/admin/mailboxes', name: 'Mailroom/Admin/Mailboxes', view: 'Mailroom/Admin/Mailboxes', title: 'Mailroom Mailboxes', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Office Services' ] },
  { path: '/mailroom/admin/notifications', name: 'Mailroom/Admin/Notifications', view: 'Mailroom/Admin/Notifications', title: 'Mailroom Notifications', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Office Services' ] },
  { path: '/mailroom/admin/notifications/:id', name: 'Mailroom/Admin/NotificationDetail', view: 'Mailroom/Admin/NotificationDetail', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Office Services' ] },
  { path: '/mailroom/admin/package', name: 'Mailroom/Admin/Package/List', view: 'Mailroom/Admin/Package/List', title: 'Mailroom Packages', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Office Services' ] },
  { path: '/mailroom/admin/package/add', name: 'Mailroom/Admin/Package/Add', view: 'Mailroom/Admin/Package/Add', title: 'Mailroom Packages', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Office Services' ] },
  { path: '/mailroom/admin/package/:id', name: 'Mailroom/Admin/Package/Detail', view: 'Mailroom/Admin/Package/Detail', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Office Services' ] },
  { path: '/mailroom/admin/reports', name: 'Mailroom/Admin/Reports', view: 'Mailroom/Admin/Reports', title: 'Mailroom Reports', includeInMenu: true, loginRequired: true, roles: ['Technology Services', 'Office Services'] },
  { path: '/mailroom/admin/settings', name: 'Mailroom/Admin/Settings', view: 'Mailroom/Admin/Settings', title: 'Mailroom Settings', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Office Services' ] },

  { path: '/med/register', name: 'M.Ed. Registration', view: 'Med/Register', title: 'M.Ed. Registration', includeInMenu: false, loginRequired: true },
  { path: '/med/admin', name: 'M.Ed. Reg Admin', view: 'Med/Admin', title: 'M.Ed. Reg Admin', includeInMenu: false, loginRequired: true, roles: ['Records', 'Education', 'Technology Services'] },

  { path: '/printers', name: 'PrintDrivers', view: 'Printers', title: 'Covenant Print Drivers', includeInMenu: false, loginRequired: false },
  // { path: '/profile', name: 'Profile', view: 'System/Profile', title: 'User Profile', includeInMenu: false, loginRequired: true },

  // { path: '/ride-share', name: 'RideShareDashboard', view: 'RideShare/Dashboard', title: 'RideShare Dashboard', includeInMenu: false, loginRequired: true },
  // { path: '/ride-share/available-rides', name: 'RideShareAvailableRides', view: 'RideShare/AvailableRides', title: 'Available Rides', includeInMenu: false, loginRequired: true },
  // { path: '/ride-share/schedule-ride', name: 'ScheduleRide', view: 'RideShare/ScheduleRide', title: 'Schedule Ride', includeInMenu: false, loginRequired: true },

  { path: '/serve', name: 'ServeHome', view: 'Serve/Home', title: 'SERVE', includeInMenu: true, loginRequired: true, roles: ['Technology Services'] },
  { path: '/serve/admin', name: 'ServeAdmin', view: 'Serve/Admin', title: 'SERVE Admin', includeInMenu: true, loginRequired: true, roles: ['Technology Services', 'Student Development'] },
  { path: '/serve/super', name: 'ServeSuper', view: 'Serve/Super', title: 'SERVE Super', includeInMenu: true, loginRequired: true, roles: ['Technology Services'] },

  // { path: '/safety/keys', name: 'KeyRequest', view: 'Safety/Keys', title: 'Key Request Form', includeInMenu: false, loginRequired: true },

  { path: '/student', name: 'Student/Dashboard', view: 'Student/Dashboard', title: 'Student Dashboard', includeInMenu: true, loginRequired: true },
  { path: '/student/capstone', name: 'Student/Capstone', view: 'Student/Capstone', title: 'Student Capstone', includeInMenu: false, loginRequired: true },
  { path: '/student/checklist', name: 'Student/Checklist', view: 'Student/Confirmation/Checklist', title: 'Student Enrollment Confirmation', includeInMenu: true, loginRequired: true },
  { path: '/student/checklist/:term/:formName', name: 'Student/Checklist/Item', view: 'Student/Confirmation/Form', loginRequired: true },
  { path: '/student/housing', name: 'Student/Housing/Home', view: 'Student/Housing', title: 'Student Housing Dashboard', includeInMenu: false, loginRequired: true },
  // { path: '/student/music-jury', name: 'StudentMusicJury', view: 'Student/MusicJury/Student', title: 'Student Music Jury', includeInMenu: true, loginRequired: true },
  // { path: '/student/music-jury/admin', name: 'StudentMusicJuryAdmin', view: 'Student/MusicJury/Admin', title: 'Student Music Jury Admin', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Music' ] },
  // { path: '/student/music-jury/:id', name: 'StudentMusicJuryEvaluation', view: 'Student/MusicJury/Evaluation', title: 'Student Music Jury Evaluation', includeInMenu: false, loginRequired: true },
  { path: '/student/music', name: 'Student/Music/Home', view: 'Student/Music/Home', title: 'Student Music Home', includeInMenu: true, loginRequired: true },
  { path: '/student/music/admin', name: 'Student/Music/Admin', view: 'Student/Music/Admin/Dashboard', title: 'Student Music Admin', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Music' ] },
  { path: '/student/music/admin/events', name: 'Student/Music/Admin/Events', view: 'Student/Music/Admin/Events', title: 'Student Music Admin: Events', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Music' ] },
  { path: '/student/music/admin/jury', name: 'Student/Music/Admin/Jury', view: 'Student/Music/Admin/Jury', title: 'Student Music Admin: Jury', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Music' ] },
  { path: '/student/music/admin/setup', name: 'Student/Music/Admin/Setup', view: 'Student/Music/Admin/Setup', title: 'Student Music Admin: Jury Setup', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Music' ] },
  { path: '/student/music/admin/students', name: 'Student/Music/Admin/Students', view: 'Student/Music/Admin/Students', title: 'Student Music Admin: Students', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Music' ] },
  { path: '/student/music/jury/:id', name: 'Student/Music/Jury/Evaluation', view: 'Student/Music/Jury', title: 'Student Music Jury Evaluation', includeInMenu: false, loginRequired: true },
  { path: '/student/program-change', name: 'Student/ProgramChange', view: 'Student/ProgramChange/Form', title: 'Student Academic Program/Advisor Change', includeInMenu: false, loginRequired: true },
  // { path: '/student/program-change/status', name: 'ProgramChangeStatus', view: 'Student/ProgramChange/Status', title: 'Student Academic Program/Advisor Change Status', includeInMenu: false, loginRequired: true },
  { path: '/student/program-change/admin', name: 'Student/ProgramChange/Admin', view: 'Student/ProgramChange/Admin/List', title: 'Student Academic Program/Advisor Change Admin', includeInMenu: false, loginRequired: true, roles: [ 'All Faculty', 'Records', 'Technology Services', 'Employee' ] },
  { path: '/student/program-change/admin/:id', name: 'Student/ProgramChange/Admin/Detail', view: 'Student/ProgramChange/Admin/Detail', title: 'Student Academic Program/Advisor Change Admin Detail', includeInMenu: false, loginRequired: true, roles: [ 'All Faculty', 'Records', 'Technology Services', 'Employee' ] },
  { path: '/student/schedule', name: 'Student/Schedule', view: 'Student/Schedule/Home', title: 'Student Schedule', includeInMenu: false, loginRequired: true, roles: [ 'Students', 'All Faculty', 'All Employees', 'All Adjuncts', 'Employee' ] },
  { path: '/student/schedule/admin', name: 'Student/Schedule/Admin', view: 'Student/Schedule/Admin', title: 'Student Schedule Admin', includeInMenu: false, loginRequired: true, roles: [ 'Employee' ] },
  { path: '/student/schedule/:bannerId(@[0-9]{8})', name: 'Student/Schedule/Id', view: 'Student/Schedule/Home', title: 'Student Schedule', includeInMenu: false, loginRequired: true, roles: [ 'Employee' ] },
  { path: '/student/schedule/:id([a-f0-9]{24})', name: 'Student/Schedule/Admin/Detail', view: 'Student/Schedule/Detail', title: 'Student Schedule Detail', includeInMenu: false, loginRequired: true, roles: [ 'Employee' ] },
  { path: '/student/schedule/:placement', name: 'Student/Schedule/Placement', view: 'Student/Schedule/Home', title: 'Student Schedule', includeInMenu: false, loginRequired: true, roles: [ 'Employee' ] },
  // { path: '/student/schedule-change', name: 'ScheduleChange', view: 'Student/ScheduleChange/Status', title: 'Student Schedule Change', includeInMenu: false, loginRequired: true, roles: [ 'Students', 'All Faculty', 'All Employees', 'All Adjuncts', 'Employee' ] },
  // { path: '/student/schedule-change/updated', name: 'ScheduleChangeNew', view: 'Student/ScheduleChange/Test', title: 'Student Schedule Change (New)', includeInMenu: false, loginRequired: true, roles: [ 'Students', 'All Faculty', 'All Employees', 'All Adjuncts', 'Employee' ] },
  // { path: '/student/schedule-change/form', name: 'ScheduleChangeForm', view: 'Student/ScheduleChange/Form', loginRequired: true, roles: [ 'Students', 'Technology Services' ] },
  // { path: '/student/schedule-change/admin', name: 'ScheduleChangeAdmin', view: 'Student/ScheduleChange/Admin', loginRequired: true, roles: [ 'All Faculty', 'All Employees', 'All Adjuncts', 'Employee' ] },
  // { path: '/student/schedule-change/admin/:id', name: 'ScheduleChangeAdminDetail', view: 'Student/ScheduleChange/Details', loginRequired: true, roles: [ 'All Faculty', 'All Employees', 'All Adjuncts', 'Employee' ] },
  { path: '/student/study-abroad', name: 'Student/StudyAbroad', view: 'Student/StudyAbroad/Home', title: 'Student Study Abroad', includeInMenu: true, loginRequired: true },
  { path: '/student/study-abroad/signature/:id', name: 'Student/StudyAbroad/Signature', view: 'Student/StudyAbroad/Signature', title: 'Study Abroad Parent Signature', includeInMenu: false, loginRequired: false },
  { path: '/student/study-abroad/admin', name: 'Student/StudyAbroad/Admin', view: 'Student/StudyAbroad/Admin/List', title: 'Study Abroad Admin', includeInMenu: false, loginRequired: true, roles: [ 'Employee' ] },
  { path: '/student/study-abroad/admin/:id', name: 'Student/StudyAbroad/Admin/App', view: 'Student/StudyAbroad/Admin/App', title: 'Study Abroad Admin', includeInMenu: false, loginRequired: true, roles: [ 'Employee' ] },
  { path: '/student/study-abroad/:id', name: 'Student/StudyAbroad/App', view: 'Student/StudyAbroad/Apply', title: 'Study Abroad Application', includeInMenu: false, loginRequired: true },

  { path: '/tech', name: 'TechHome', view: 'Tech/Home', title: 'Home', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'Technology Services I', 'Technology Services II', 'AV', 'AV I - Classroom', 'AV I - Events' ] },
  // { path: '/tech/timeclock', name: 'TimeclockManager', title: 'Timeclock', view: 'Tech/Timeclock/List', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'HR', 'Financial Aid', 'Employee' ] },
  // { path: '/tech/timeclock/detail', name: 'TimeclockManagerDetail', view: 'Tech/Timeclock/Detail', loginRequired: true, roles: [ 'Technology Services', 'Employee' ] },
  // { path: '/tech/timeclock/:id', name: 'TimeclockManagerDetailId', view: 'Tech/Timeclock/Detail', loginRequired: true, roles: [ 'Technology Services' ] },
  // { path: '/tech/mac', name: 'TechMacAddress', view: 'Tech/Mac', title: 'Mac Address Registration', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'Technology Services I', 'Technology Services II', 'AV', 'AV I - Classroom', 'AV I - Events' ] },

  { path: '/scotswork', name: 'ScotsWork/Home', view: 'ScotsWork/Home', title: 'ScotsWork Home', includeInMenu: true, loginRequired: true },
  { path: '/scotswork/admin', name: 'ScotsWork/Admin', view: 'ScotsWork/Admin', title: 'ScotsWork Tracker', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'HR', 'Financial Aid', 'Employee' ] },
  { path: '/scotswork/contract', name: 'ScotsWork/Contract', view: 'ScotsWork/Contract', title: 'ScotsWork Contract', includeInMenu: false, loginRequired: true },
  { path: '/scotswork/timeclock', name: 'ScotsWork/Timeclocks', view: 'ScotsWork/Timeclock/List', title: 'ScotsWork Timeclocks', includeInMenu: true, loginRequired: true, roles: [ 'Technology Services', 'HR', 'Financial Aid', 'Employee' ] },
  { path: '/scotswork/timeclock/detail', name: 'ScotsWork/Timeclock/Detail', view: 'ScotsWork/Timeclock/Detail', title: 'ScotsWork Timeclock Detail', includeInMenu: false, loginRequired: true, roles: [ 'Technology Services', 'HR', 'Financial Aid', 'Employee' ] },
  { path: '/scotswork/timeclock/log', name: 'ScotsWork/TImeclock/Log', view: 'ScotsWork/Timeclock/Log', title: 'ScotsWork Timeclock Logs', includeInMenu: true, loginRequired: true, roles: ['Technology Services'] },
  { path: '/scotswork/timeclock/usage', name: 'ScotsWork/Timeclock/Usage', view: 'ScotsWork/Timeclock/Usage', title: 'ScotsWork Timeclock Usage', includeInMenu: false, loginRequired: true, roles: ['Technology Services'] },

  { path: '/system/spoof', name: 'System/Spoof', view: 'System/Spoof', loginRequired: true, roles: [ 'Technology Services' ] },
  { path: '/system/drive-folders', name: 'System/DriveFolders', view: 'System/DriveFolders', loginRequired: true, includeInMenu: false, roles: ['Technology Services'] },
  { path: '/system/links', name: 'System/Links', view: 'System/Links', loginRequired: true, includeInMenu: false, roles: ['All Staff'] },
  { path: '/system/usage', name: 'System/Usage', view: 'System/Usage', loginRequired: true, includeInMenu: false, roles: ['Technology Services'] },
  { path: '/error', name: '404PageNotFound', view: 'System/Error', loginRequired: false, includeInMenu: false },
  { path: '*', name: 'PageNotFound', view: 'System/Error', loginRequired: false, includeInMenu: false }
]
