import { stringFormatDateToday } from '@/helpers/formatters'

export default {
  async loadTimeclocks (context) {
    const query = { locked: true, $limit: 50, $sort: { location: 1 } }
    const { data } = await this.$feathers.service('timeclock/location').find({ query })
    data.push({
      _id: '611427510f397e32383431ec',
      shortName: 'Grounds Shop'
    })
    data.push({
      _id: '611427670f397e32383431ef',
      shortName: 'Kirk'
    })
    context.commit('setTimeclocks', data)
  },
  async loadPlacements (context, { pidm, aidy }) {
    if (!aidy) {
      const dt = new Date()
      aidy = (dt.getFullYear() % 100) * 101 + 1
      if (dt.getMonth() < 6) {
        aidy -= 101
      }
    }
    const placements = []
    const { data } = await this.$feathers.service('work-study/students').find({ query: { aidy, pidm } })
    for (const { _id, code, position: posn, suff, hours: { auth, remaining }, track: { compPerc, targetPerc }, supervisor: { name, email }, updatedAt } of data) {
      const obj = { _id, placement: code, authHrs: auth, remainingHrs: remaining, compPerc, targetPerc, superName: name, superEmail: email, updatedAt }
      // Determine whether they are ahead, behind, or on track.
      // We load a flat difference in the percentages, but we also get a ratio for how much time has passed.
      // This allows us to have a more accurate view early in the semester with smaller percentages.
      const diff = Math.abs(targetPerc - compPerc)
      obj.diff = diff
      const ratio = diff / compPerc * 100
      obj.ratio = ratio
      const aheadOrBehind = (compPerc > targetPerc ? 'ahead' : 'behind')
      if (ratio < 5 || diff < 3) {
        obj.targetColor = 'success'
        obj.targetText = 'You are on track'
        if (ratio > 5) obj.targetText += ', but just a little ' + aheadOrBehind
      } else if (ratio < 20 || diff < 10) {
        obj.targetColor = 'warning'
        obj.targetText = 'You are getting a little ' + aheadOrBehind
      } else if (aheadOrBehind === 'behind') {
        obj.targetColor = 'error'
        obj.targetText = 'You have fallen behind the target for this point in the year'
      } else {
        obj.targetColor = 'error'
        obj.targetText = 'You have gotten ahead of the target for this point in the year'
      }
      // Load placement details (supervisor and position name)
      const { data: placementData } = await this.$feathers.service('work-study/placements').find({ query: { code } })
      if (placementData.length > 0) {
        obj.placement = placementData[0].name
      }
      // Load timeclock data to get the latest clock-in shift
      const { data: timeclockData } = await this.$feathers.service('timeclock/punch').find({ query: { pidm, posn, suff, $sort: { 'in.date': -1 }, $limit: 1 } })
      if (timeclockData.length > 0) {
        obj.clockIn = stringFormatDateToday(timeclockData[0].in.date)
        obj.clockStatus = timeclockData[0].status
        if ('out' in timeclockData[0]) obj.clockOut = stringFormatDateToday(timeclockData[0].out.date)
        else if (timeclockData[0].status === 'R') obj.clockOut = '**You forgot to clock out**'
        else if (timeclockData[0].status === 'P') obj.clockOut = '**You are still clocked in**'
      }
      // Add this placement to the student's list of placements
      placements.push(obj)
    }
    return placements
  }
}
